  <!-- Site footer -->
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <h6 style="padding-right: 36px">روابط سريعة</h6>
          <ul class="footer-links">
            <li><a href="/about">حول الموقع</a></li>
            <li><a href="/contact">اتصل بنا</a></li>
            <li><a href="/contribute">تبرع للموقع</a></li>
            <li><a href="/privacy-policy">سياسية الخصوصية</a></li>
          </ul>
        </div>

        <div class="col-sm-6 col-md-3">
          <h6 style="padding-right: 36px">فئات</h6>
          <ul class="footer-links">
            <li><a href="https://wajabat.modakira.com/">مدونة الطبخ</a></li>
          </ul>
        </div>

        <div class="col-sm-12 col-md-6">
          <h6>هدف الموقع</h6>
          <p class="text-justify">موداكيرا هو مكان للتعلم الممتع، يركز بشكل أساسي على اللغة الألمانية. هنا، يمكنك أن تشق طريقك نحو التمكن من اللغة، بغض النظر عن الدوافع الشخصية أو التطلعات للانتقال إلى ألمانيا. التعلم هنا مفتوح للجميع، الصغار والكبار على حد سواء، مع توصيات عمرية مناسبة.

            نقدم مجموعة متنوعة من البرامج التعليمية التي تلبي احتياجات مختلف الأعمار والاهتمامات. سواء كنت تتعلم لأسباب شخصية أو مهنية، فإن موداكيرا يوفر بيئة تعليمية شاملة وداعمة تساعدك على تحقيق أهدافك في تعلم اللغة الألمانية.
            
            نحن نؤمن بأن التعلم يجب أن يكون ممتعًا وجذابًا، لذا نحرص على تقديم دروس تفاعلية وأساليب تعليمية مبتكرة تجعل عملية التعلم أكثر فاعلية وسهولة. انضم إلينا في موداكيرا واستمتع برحلتك التعليمية نحو إتقان اللغة الألمانية.</p>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">حقوق النشر <br />
            <a href="#">جميع الحقوق لموقع modakira.com 
       محفوظة، لا يسمح باستخدام محتوى هذا الموقع دون اتفاق مسبق مع إدارة الموقع </a><br /> &copy; Modakira 2024 <br />.
          </p>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <li><a class="facebook"target="_blank"  href="https://www.facebook.com/bl0ckmaster"><i class="fab fa-facebook"></i></a></li>
            <li><a class="twitter" target="_blank" href="https://x.com/@modakiracom"><i class="fab fa-twitter"></i></a></li>
            <li><a class="dribbble" target="_blank" href="https://www.youtube.com/@deutschmitamin"><i class="fab fa-youtube"></i></a></li>
            <li><a class="linkedin" target="_blank" href="https://www.tiktok.com/@deutschmitamin"><i class="fab fa-tiktok"></i></a></li>   
          </ul>
        </div>
      </div>
    </div>
</footer>