<div dir="ltr" style=" padding-top: 60px; ">
  <!-- <div class="tabs-container">
        <button class="nav-btn" (click)="prevTab()">&lt;</button>
        <div class="tabs-wrapper">
          <div class="tabs" [style.transform]="translateX">
            <button (click)="selectTab(1)" [class.active]="selectedTab === 1">Zimmer zu vermieten</button>
            <button (click)="selectTab(2)" [class.active]="selectedTab === 2">Einladung zum Geburtstag</button>
            <button (click)="selectTab(3)" [class.active]="selectedTab === 3">Postkarte aus dem Urlaub</button>
            <button (click)="selectTab(4)" [class.active]="selectedTab === 4">Medien im Alltag</button>
            <button (click)="selectTab(5)" [class.active]="selectedTab === 5">Unternehmungen</button>
            <button (click)="selectTab(6)" [class.active]="selectedTab === 6">Halloween</button>
          </div>
        </div>
        <button class="nav-btn" (click)="nextTab()">&gt;</button>
      </div> -->
  <div class="tabs-dropdown">
    <select (change)="selectTab($event)">
      <option value="1" [selected]="selectedTab === 1">Zimmer zu vermieten</option>
      <option value="2" [selected]="selectedTab === 2">Einladung zum Geburtstag</option>
      <option value="3" [selected]="selectedTab === 3">Postkarte aus dem Urlaub</option>
      <option value="4" [selected]="selectedTab === 4">Medien im Alltag</option>
      <option value="5" [selected]="selectedTab === 5">Unternehmungen</option>
      <option value="6" [selected]="selectedTab === 6">Halloween</option>
      <option value="7" [selected]="selectedTab === 7">Ämter</option>
      <option value="8" [selected]="selectedTab === 8">Deutschland</option>
      <option value="9" [selected]="selectedTab === 9">Plan 1</option>
      <option value="10" [selected]="selectedTab === 10">Mietshaus Regeln</option>
      <option value="11" [selected]="selectedTab === 11">Flughafen</option>
      <option value="12" [selected]="selectedTab === 12">Arbeit und Ausbildung</option>
    </select>
  </div>



  <div class="tab-content" *ngIf="selectedTab === 1">
    <div class="anzeige">
      <h2>Zimmer zu vermieten</h2>
      <p><strong>Ort</strong>: Berlin, Neukölln</p>
      <p><strong>Größe</strong>: 20 m²</p>
      <p><strong>Preis</strong>: 350 € pro Monat</p>
      <p><strong>Möbliert</strong>: Ja</p>
      <p><strong>Beschreibung</strong>: Helles Zimmer in einer 3er-WG. Küche und Bad werden geteilt. Nur für
        Nichtraucher. Keine Haustiere erlaubt. In der Nähe von öffentlichen Verkehrsmitteln (U-Bahn und Bus).
        Einkaufsmöglichkeiten in der Nähe.</p>
      <p><strong>Kontakt</strong>: Bitte per E-Mail an info&#64;zimmerberlin.de</p>
    </div>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 2">
    <div class="email">
      <h2>Einladung zum Geburtstag</h2>
      <p><strong>Betreff</strong>: Einladung zu meinem Geburtstag</p>
      <p>Liebe Sarah,</p>
      <p>ich möchte dich herzlich zu meinem Geburtstag einladen!</p>
      <p><strong>Wann</strong>: Samstag, 15. Oktober</p>
      <p><strong>Uhrzeit</strong>: 18:00 Uhr</p>
      <p><strong>Wo</strong>: Bei mir zu Hause, Musterstraße 123, 10115 Berlin</p>
      <p>Wir essen Pizza und trinken zusammen. Bitte gib mir bis zum <strong>12. Oktober</strong> Bescheid, ob du
        kommen kannst.</p>
      <p>Ich freue mich auf dich!</p>
      <p>Liebe Grüße,</p>
      <p><strong>Anna</strong></p>
    </div>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 3">
    <div class="postkarte">
      <h2>Grüße aus dem Urlaub</h2>
      <p>Liebe Sabine,</p>
      <p>ich bin gerade im Urlaub in <strong>Italien</strong>. Das Wetter ist wunderbar! Die Sonne scheint und der
        Strand ist super.</p>
      <p>Ich habe schon viel Pizza und Pasta gegessen. Morgen fahren wir nach <strong>Rom</strong>. Ich freue mich sehr
        darauf, den Vatikan und das Kolosseum zu sehen.</p>
      <p>Ich hoffe, es geht dir gut!</p>
      <p>Liebe Grüße,</p>
      <p><strong>Julia</strong></p>
    </div>

  </div>

  <div class="tab-content" *ngIf="selectedTab === 4">
    <div class="medien">
      <h1>Medien im Alltag</h1>
      <p>In der heutigen Zeit nutzen viele Menschen täglich verschiedene Medien. Ein sehr beliebtes Medium ist das
        Internet. Viele Menschen surfen im Internet, lesen Nachrichten oder schauen Videos. Auch soziale Netzwerke wie
        Facebook, Instagram oder TikTok sind sehr beliebt. Hier teilen Menschen Fotos, Videos oder schreiben Nachrichten
        an Freunde.</p>
      <p>Neben dem Internet gibt es auch das Fernsehen. Viele Menschen schauen abends ihre Lieblingssendung oder einen
        Film. Besonders beliebt sind Nachrichten und Unterhaltungsshows. Manche hören auch Radio auf dem Weg zur Arbeit
        oder in der Freizeit.</p>
      <p>Zeitungen und Zeitschriften lesen immer weniger Menschen, weil viele Informationen online verfügbar sind.
        Trotzdem gibt es noch einige, die gerne eine gedruckte Zeitung in der Hand halten.</p>
      <p>Insgesamt sind Medien ein wichtiger Teil unseres Alltags. Sie helfen uns, informiert zu bleiben und bieten
        Unterhaltung.</p>
    </div>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 5">
    <div class="unternehmungen">
      <h1>Ein Ausflug in die Stadt</h1>
      <p>Letztes Wochenende habe ich mit Anna einen Ausflug in die Stadt gemacht. Deshalb haben wir uns schon am Freitag
        verabredet, um früh loszufahren. Wir wollten etwas Spannendes erleben und neue Orte entdecken.</p>
      <p>Zuerst sind wir mit dem Bus zu einem großen Museum gefahren. Das Museum liegt bei dem Hauptbahnhof, deshalb war
        es leicht zu finden. Wir haben viele interessante Ausstellungen gesehen. Anna interessierte sich besonders für
        die Bilder von Van Gogh. Mein Interesse galt der Geschichte, besonders den alten römischen Statuen.</p>
      <p>Nach dem Museumsbesuch sind wir zu einem Restaurant gegangen. Dort haben wir etwas zu essen bestellt. Anna
        wählte einen Salat, aber ich wollte etwas Größeres. Ich habe eine Pizza bestellt, weil ich großen Hunger hatte.
      </p>
      <p>Am Nachmittag haben wir einen Spaziergang im Park gemacht. Das Wetter war gut, und der Park war schöner als
        der, den wir bei uns zu Hause haben. Wir haben auf einer Bank gesessen und über unsere Pläne für den Sommer
        gesprochen. Anna möchte nach Berlin reisen, und ich will zu einem Fußballspiel gehen.</p>
      <p>Zum Schluss sind wir noch mit Freunden ins Kino gegangen. Der Film war spannend, aber ich fand das Ende besser
        als Anna. Deshalb haben wir noch lange über den Film diskutiert, bevor wir nach Hause gefahren sind.</p>
    </div>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 6">
    <div class="halloween">
      <h1>Halloween</h1>
      <p>Halloween ist ein Fest, das jedes Jahr am 31. Oktober gefeiert wird. Die Menschen verkleiden sich als Geister,
        Hexen, Monster oder andere Figuren. Kinder gehen von Haus zu Haus und sagen „Süßes oder Saures“. Sie bekommen
        dann Süßigkeiten oder kleine Geschenke.</p>
      <p>Der Ursprung von Halloween liegt in alten Traditionen aus Irland. Die Menschen feierten das Fest
        <strong>Samhain</strong>, um das Ende des Sommers und den Beginn des Winters zu markieren. Sie glaubten, dass an
        diesem Tag die Geister der Toten auf die Erde kommen. Deshalb verkleideten sie sich, um die Geister zu
        vertreiben.</p>
      <p>Heute ist Halloween vor allem in den USA, Kanada und Großbritannien sehr beliebt. Viele Häuser sind mit
        Kürbissen, Spinnennetzen und gruseligen Dekorationen geschmückt.</p>
      <p>Menschen feiern Halloween auf unterschiedliche Weise. Manche machen Kostümpartys, andere schauen gruselige
        Filme. Für viele ist es ein Spaß, sich zu verkleiden und den Tag mit Freunden und Familie zu genießen.</p>
    </div>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 7">
    <h1>Orte und Institutionen: Ämter und ihre Zuständigkeiten</h1>
    <p>In Deutschland gibt es viele verschiedene Ämter, die den Bürgerinnen und Bürgern in unterschiedlichen
      Lebensbereichen weiterhelfen. Diese Ämter übernehmen wichtige Aufgaben und sorgen dafür, dass alles geordnet
      abläuft.</p>

    <p>Ein besonders bekanntes Amt ist das <strong>Bürgeramt</strong>. Hier meldet man sich an, wenn man in eine neue
      Stadt zieht. Außerdem ist das Bürgeramt zuständig für die Ausstellung von Personalausweisen und Reisepässen. Wer
      in Deutschland wohnt, muss sich beim Umzug in eine neue Stadt dort anmelden.</p>

    <p>Ein weiteres wichtiges Amt ist die <strong>Agentur für Arbeit</strong>, oft auch <strong>Arbeitsamt</strong>
      genannt. Hier bekommen Menschen Unterstützung, wenn sie auf der Suche nach Arbeit sind. Zudem bietet das
      Arbeitsamt Beratung zu Ausbildung und Weiterbildung an. Es vermittelt Jobs oder Kurse, die helfen, bessere Chancen
      auf dem Arbeitsmarkt zu haben.</p>

    <p>Das <strong>Finanzamt</strong> ist in Deutschland für alles rund um Steuern verantwortlich. Jedes Jahr müssen die
      Menschen eine Steuererklärung abgeben. Das Finanzamt berechnet dann, ob man Steuern nachzahlen muss oder ob man
      Geld zurückbekommt. Bei Fragen zur Steuer hilft das Finanzamt ebenfalls weiter.</p>

    <p>Wichtige Ereignisse im Leben, wie Geburten, Hochzeiten oder Todesfälle, werden im <strong>Standesamt</strong>
      registriert. Hier erhält man die Geburtsurkunde eines Kindes oder meldet sich, wenn man heiraten möchte. Auch die
      Sterbeurkunde nach einem Todesfall wird hier ausgestellt.</p>

    <p>Das <strong>Gesundheitsamt</strong> kümmert sich um die Gesundheit der Bevölkerung. Es prüft, ob
      Hygienevorschriften eingehalten werden, und sorgt dafür, dass in Schulen und Kindergärten auf die Gesundheit der
      Kinder geachtet wird. Besonders in Krisenzeiten, wie bei einer Pandemie, spielt das Gesundheitsamt eine zentrale
      Rolle.</p>

    <p>Für Menschen, die aus einem anderen Land nach Deutschland kommen, gibt es das <strong>Ausländeramt</strong>. Hier
      bekommt man Unterstützung bei der Beantragung von Aufenthaltsgenehmigungen oder Visa und erhält Antworten auf
      Fragen, die das Leben und Arbeiten in Deutschland betreffen.</p>

    <p>Jedes dieser Ämter hat seine eigenen Zuständigkeiten und hilft den Menschen in verschiedenen Bereichen des
      Lebens. Es ist wichtig zu wissen, welches Amt für welche Aufgaben verantwortlich ist, damit man sich im richtigen
      Moment an die passende Stelle wenden kann.</p>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 8">
    <h1>Was macht Deutschland besonders?</h1>

    <p>Deutschland hat viele Dinge, die gut sind. Viele Menschen aus anderen Ländern sagen: In Deutschland ist vieles
      besser. Zum Beispiel sind die Lebensmittel hier oft billig. Man kann in Supermärkten wie Aldi und Lidl günstig
      einkaufen. Auch auf den Straßen und Autobahnen gibt es viele Vorteile. Die Straßen sind gut gepflegt, und auf den
      Autobahnen gibt es oft kein Tempolimit. Das heißt, man kann schnell fahren.</p>

    <p>Ein anderer Vorteil in Deutschland ist das Recycling. Es gibt ein System mit Pfand für Flaschen und Dosen. Das
      heißt, man kann leere Flaschen und Dosen zurückbringen und bekommt dafür Geld. Das hilft, die Umwelt zu schützen.
    </p>

    <p>Deutschland hat auch viele Ideen für Energie. Es gibt viel Ökostrom, also Strom aus Sonne und Wind. Die Preise
      für Strom bleiben oft stabil. Das ist für viele Menschen wichtig.</p>

    <p>In Deutschland gibt es auch eine gute Ausbildung. Junge Leute können lernen und gleichzeitig in einem Beruf
      arbeiten, zum Beispiel als Lehrling. Das ist ein gutes System.</p>

    <p>Die Krankenversicherung ist auch gut. In Deutschland haben fast alle Menschen eine Versicherung. Es gibt viele
      Ärzte und Krankenhäuser, und viele Menschen können gut versorgt werden.</p>

    <p>Kultur ist in Deutschland für alle da. Es gibt viele Theater und Museen, und oft kann man diese günstig besuchen.
      Das ist wichtig für alle Menschen, die Kunst und Kultur mögen.</p>

    <p>In den Städten in Deutschland sind die Mieten oft günstiger als in anderen Ländern, wie zum Beispiel in der
      Schweiz. </p>

    <p>Deutschland hat auch gute Gesetze für die Arbeit. Diese Gesetze schützen die Menschen. Zum Beispiel sind die
      Arbeitszeiten geregelt, und man hat einen guten Kündigungsschutz.</p>

    <p>Umweltschutz ist in Deutschland auch wichtig. Es gibt viele Fahrradwege und Regeln für die Luft. Das heißt,
      Deutschland möchte die Umwelt sauber halten.</p>

    <p>Zusammengefasst: Deutschland ist in vielen Bereichen gut und besonders. Es ist ein Land, das viele Menschen aus
      der ganzen Welt mögen.</p>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 9">
    <h1>Planung eines Ausflugs</h1>
    <div class="dialogue">
      <p><span class="person1">Person1:</span> Hallo Person2! Hast du schon Pläne fürs Wochenende?</p>
      <p><span class="person2">Person2:</span> Noch nicht wirklich. Hast du eine Idee, was wir machen könnten?</p>
      <p><span class="person1">Person1:</span> Vielleicht könnten wir einen Tagesausflug machen. Was hältst du davon?
      </p>
      <p><span class="person2">Person2:</span> Das klingt gut! Wohin könnten wir fahren?</p>
      <p><span class="person1">Person1:</span> Wie wäre es, wenn wir in die Berge gehen würden? Wir könnten wandern und
        die Aussicht genießen.</p>
      <p><span class="person2">Person2:</span> Das wäre toll! Aber ich habe keine Wanderschuhe.</p>
      <p><span class="person1">Person1:</span> Das macht nichts. Vielleicht könntest du dir welche ausleihen. Ich hätte
        auch ein Paar, das dir passen könnte.</p>
      <p><span class="person2">Person2:</span> Super, danke! Wann sollten wir losfahren?</p>
      <p><span class="person1">Person1:</span> Ich würde vorschlagen, dass wir früh am Morgen starten, damit wir genug
        Zeit haben. Vielleicht um 7 Uhr?</p>
      <p><span class="person2">Person2:</span> Um 7 Uhr? Das wäre mir ein bisschen zu früh. Könnten wir nicht um 8 Uhr
        losfahren?</p>
      <p><span class="person1">Person1:</span> Ja, das wäre auch in Ordnung. Was ist mit dem Essen? Sollen wir etwas
        mitnehmen?</p>
      <p><span class="person2">Person2:</span> Ja, ich denke, wir sollten ein Picknick machen. Ich könnte Sandwiches
        vorbereiten.</p>
      <p><span class="person1">Person1:</span> Das klingt gut. Ich würde Getränke und Obst mitbringen.</p>
      <p><span class="person2">Person2:</span> Perfekt! Und wie kommen wir dorthin?</p>
      <p><span class="person1">Person1:</span> Wir könnten mit dem Auto fahren. Ich könnte mein Auto nehmen, wenn das
        für dich okay ist.</p>
      <p><span class="person2">Person2:</span> Ja, das wäre super. Soll ich mich um die Musik für die Fahrt kümmern?</p>
      <p><span class="person1">Person1:</span> Ja, mach das! Ich freue mich schon darauf.</p>
      <p><span class="person2">Person2:</span> Ich auch. Lass uns alles morgen nochmal durchsprechen und die Details
        klären.</p>
      <p><span class="person1">Person1:</span> Alles klar. Bis morgen!</p>
    </div>
  </div>


  <div class="tab-content" *ngIf="selectedTab === 10">
    <h1>Zusammenleben im Mietshaus: Wichtige Tipps und Regeln</h1>
    <p>Das Leben in einem Mietshaus bedeutet, dass viele Menschen zusammen unter einem Dach wohnen. Damit alle gut
      miteinander auskommen, gibt es Regeln und Pflichten. Hier sind die wichtigsten Informationen:</p>

    <h2>Regeln im Mietshaus</h2>
    <p>In jedem Mietshaus gibt es Regeln, die von allen beachtet werden müssen. Viele davon stehen in der
      <strong>Hausordnung</strong>. Hier ein paar Beispiele:</p>
    <ul>
      <li><strong>Ruhezeiten:</strong> In der Regel gelten Ruhezeiten von 22:00 Uhr bis 6:00 Uhr. In dieser Zeit soll es
        leise sein, damit niemand gestört wird.</li>
      <li><strong>Treppenhaus:</strong> Das Treppenhaus muss sauber bleiben. Manche Mietparteien wechseln sich mit dem
        Putzen ab.</li>
      <li><strong>Mülltrennung:</strong> Jeder muss darauf achten, den Müll richtig zu trennen und die Tonnen ordentlich
        zu benutzen.</li>
    </ul>
    <p>Wer sich an die Regeln hält, sorgt für ein harmonisches Zusammenleben.</p>

    <h2>Kommunikation mit der Vermieterin / dem Vermieter</h2>
    <p>Manchmal gibt es Probleme, die nur die Vermieterin oder der Vermieter lösen kann. Zum Beispiel:</p>
    <ul>
      <li><strong>Reparaturen:</strong> Wenn der Wasserhahn kaputt ist oder die Heizung nicht funktioniert, muss der
        Vermieter informiert werden. Am besten schreibt man eine <strong>E-Mail</strong> oder einen
        <strong>Brief</strong>, damit alles dokumentiert ist.</li>
      <li><strong>Fragen zur Miete:</strong> Wenn es Unklarheiten bei der Miete gibt, sollte man freundlich nachfragen.
        Eine gute Kommunikation ist wichtig.</li>
    </ul>
    <p><em>Tipp:</em> Immer höflich bleiben und genau erklären, worum es geht.</p>

    <h2>Reparaturen und Renovierung</h2>
    <ul>
      <li><strong>Reparaturen:</strong> Der Vermieter ist für große Reparaturen verantwortlich, wie zum Beispiel eine
        defekte Heizung oder undichte Fenster.</li>
      <li><strong>Renovierung:</strong> Mieter sind meist nur für kleine Arbeiten wie das Streichen der Wände zuständig.
        Vor größeren Renovierungen sollte man immer die Erlaubnis des Vermieters einholen.</li>
    </ul>

    <h2>Konflikte mit Nachbarn</h2>
    <p>Manchmal gibt es Streit mit Nachbarn, z. B. wegen Lärm oder eines schmutzigen Treppenhauses. Hier ein paar Tipps,
      wie man Konflikte löst:</p>
    <ul>
      <li><strong>Direkt sprechen:</strong> Oft hilft ein freundliches Gespräch. Viele Probleme können so schnell
        geklärt werden.</li>
      <li><strong>Hausverwaltung informieren:</strong> Wenn das Gespräch nicht hilft, kann man die Hausverwaltung oder
        den Vermieter um Hilfe bitten.</li>
      <li><strong>Gelassen bleiben:</strong> Streit ist nie angenehm. Es ist wichtig, ruhig zu bleiben und nach Lösungen
        zu suchen.</li>
    </ul>

    <h2>Fazit</h2>
    <p>Das Zusammenleben im Mietshaus kann angenehm sein, wenn sich alle an die Regeln halten und respektvoll
      miteinander umgehen. Gute Kommunikation, Rücksichtnahme und Verständnis sind der Schlüssel zu einem friedlichen
      Miteinander.</p>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 11">
    <h1>Am Flughafen</h1>
        <p>Am Flughafen herrscht viel Betrieb. Die Menschen stehen an den Schaltern, warten auf ihren Flug oder beeilen sich, zum Gate zu kommen. Familie Wagner ist gerade angekommen, um in den Urlaub nach Spanien zu fliegen. Sie haben ihre Tickets und Reisepässe bereit. Am <span class="highlight">Check-in-Schalter</span> gibt es jedoch ein Problem.</p>
        <p>„Ihr Koffer wiegt 25 Kilogramm. Das sind 5 Kilogramm zu viel,“ erklärt die Mitarbeiterin freundlich. „Sie können entweder <span class="highlight">umpacken</span> oder eine Gebühr von 50 Euro bezahlen.“</p>
        <p>Herr Wagner schaut <span class="highlight">genervt</span>, aber seine Frau bleibt ruhig. „Können wir vielleicht etwas ins Handgepäck legen?“ fragt sie höflich. Die Mitarbeiterin nickt: „Ja, das ist eine gute Lösung.“</p>
        <p>Nachdem das Gepäck aufgegeben wurde, geht die Familie zur Sicherheitskontrolle. Dort müssen sie ihre Jacken, Taschen und Flüssigkeiten in kleine Plastikbeutel packen. Herr Wagner vergisst, sein <span class="highlight">Gürtel abzunehmen</span>, und der Sicherheitsalarm piept. Ein Sicherheitsmitarbeiter bittet ihn, <span class="highlight">zurückzugehen</span> und es noch einmal zu versuchen.</p>
        <p>Nach der Kontrolle suchen sie ihr Gate und haben noch Zeit, bevor der Flug beginnt. Sie kaufen Wasser und Snacks, um sich die Wartezeit zu verkürzen. Als die Durchsage kommt, dass das Boarding startet, stellen sie sich in die Schlange.</p>
        <p>Im Flugzeug setzt sich Familie Wagner auf ihre Plätze. „Es war ein bisschen stressig, aber jetzt beginnt der Urlaub,“ sagt Frau Wagner lächelnd. Herr Wagner nickt: „Ja, Hauptsache, wir sind pünktlich.“</p>
        
        <div class="questions">
            <h2>Fragen zum Text:</h2>
            <ul>
                <li>Warum muss Familie Wagner beim Check-in eine Lösung finden?</li>
                <li>Was passiert bei der Sicherheitskontrolle?</li>
                <li>Was machen sie, um die Wartezeit am Gate zu verbringen?</li>
                <li>Wie reagiert Herr Wagner am Ende?</li>
            </ul>
        </div>

        <h2>Vokabelhilfe:</h2>
        <ul>
            <li><span class="highlight">der Check-in-Schalter</span>: der Bereich, wo man sein Gepäck aufgibt</li>
            <li><span class="highlight">die Sicherheitskontrolle</span>: die Kontrolle von Personen und Gepäck auf gefährliche Gegenstände</li>
            <li><span class="highlight">das Handgepäck</span>: die Tasche, die man mit ins Flugzeug nehmen darf</li>
            <li><span class="highlight">das Boarding</span>: das Einsteigen ins Flugzeug</li>
        </ul>
        
        <h2>Grammatik-Tipps:</h2>
        <ul>
            <li><strong>Trennbare Verben:</strong> z. B. <span class="highlight">aufgeben</span> (Ich <em>gebe</em> das Gepäck <em>auf</em>.)</li>
            <li><strong>Modalverben:</strong> z. B. <span class="highlight">können</span> (Wir <em>können</em> umpacken.)</li>
            <li><strong>Perfekt:</strong> z. B. <span class="highlight">ist angekommen</span> (Frau Wagner <em>ist angekommen</em>.)</li>
        </ul>
  </div>

  <div class="tab-content" *ngIf="selectedTab === 12">
    <h1>Ausbildung und Arbeit in Deutschland</h1>
        <p>
            In Deutschland gibt es viele Möglichkeiten, einen Beruf zu lernen. Man kann eine Ausbildung machen oder studieren.
        </p>
        
        <h2>Was ist eine Ausbildung?</h2>
        <p>
            Eine Ausbildung ist eine Kombination aus Schule und Arbeit. Du lernst in der Berufsschule die Theorie. 
            Gleichzeitig arbeitest du in einem Betrieb und lernst die Praxis.
        </p>
        <p>
            Während der Ausbildung bekommst du jeden Monat Geld. Nach der Ausbildung hast du einen Abschluss. 
            Viele Auszubildende bekommen danach einen festen Job.
        </p>

        <h2>Was ist ein Studium?</h2>
        <p>
            Wenn du studieren möchtest, musst du vorher das Abitur machen. An der Universität lernst du viel Theorie. 
            Ein Studium dauert oft drei bis fünf Jahre. Mit einem Abschluss hast du gute Chancen, einen Job zu finden.
        </p>
        <p>
            Manche Firmen bieten ein „Duales Studium“ an. Du lernst an der Universität und arbeitest gleichzeitig in einem Betrieb.
        </p>

        <h2>Der Arbeitsmarkt in Deutschland</h2>
        <p>
            In Deutschland gibt es viele Berufe. Besonders in den Bereichen IT, Pflege, Technik und Handwerk werden Mitarbeiter gesucht.  
        </p>
        <p>
            Es ist wichtig, dass du dich weiterbildest. Du kannst neue Kurse besuchen und neue Fähigkeiten lernen. 
            So hast du immer gute Chancen, einen Job zu finden.
        </p>

        <h2>Tipps für die Jobsuche</h2>
        <ul>
            <li>Geh zur Berufsberatung. Sie hilft dir, den richtigen Beruf zu finden.</li>
            <li>Bau ein Netzwerk auf. Spreche mit anderen Menschen über Arbeit.</li>
            <li>Schreibe eine gute Bewerbung mit Lebenslauf und Anschreiben.</li>
            <li>Lerne die deutsche Sprache. Viele Firmen suchen Mitarbeiter, die gut Deutsch sprechen.</li>
        </ul>

        <h2>Fazit</h2>
        <p>
            In Deutschland gibt es viele Möglichkeiten, einen Beruf zu lernen und Arbeit zu finden. 
            Eine gute Ausbildung und Sprachkenntnisse helfen dir, erfolgreich zu sein.
        </p>
  </div>
</div>