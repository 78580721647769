<div class="container">
    <div class="image-slide-left" [ngClass]="{'slide-complete': isSlideComplete}">
        <img [src]="leftImage" width="30%" height="30%" alt="Left Image">
        <div class="text-container">
            <span [ngStyle]="{'color': textColor}" class="germantext">{{ leftText }}</span>
            <div class="translation">{{ leftTranslation }}</div>
        </div>
    </div>

    <div class="image-slide-right" [ngClass]="{'slide-complete': isSlideComplete}">
        <img [src]="rightImage" width="30%" height="30%" alt="Right Image">
        <div class="text-container">
            <span [ngStyle]="{'color': textColor}" class="germantext">{{ rightText }}</span>
            <div class="translation">{{ rightTranslation }}</div>
        </div>
    </div>

    <div class="buttons">
        <button class="stop-button button" (click)="stopAnimation()">Stop</button>
        <button class="start-button button" (click)="startAnimation()">Start</button>
    </div>
</div>