import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-adjektive',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './adjektive.component.html',
  styleUrls: ['./adjektive.component.scss'] // styleUrl -> styleUrls
})
export class AdjektiveComponent implements AfterViewInit {
  adjectives = [
    {
      leftImage: 'adjektive/glücklich.webp',
      leftText: 'Glücklich',
      leftTranslation: 'سعيد',
      rightImage: 'adjektive/traurig.webp',
      rightText: 'Traurig',
      rightTranslation: 'حزين'
    },
    {
      leftImage: 'adjektive/glücklich.webp',
      leftText: 'Glücklich',
      leftTranslation: 'سعيد',
      rightImage: 'adjektive/traurig.webp',
      rightText: 'Traurig',
      rightTranslation: 'حزين'
    },
    {
      leftImage: 'adjektive/groß.webp',
      leftText: 'Groß',
      leftTranslation: 'كبير',
      rightImage: 'adjektive/klein.webp',
      rightText: 'Klein',
      rightTranslation: 'صغير'
    },
    {
      leftImage: 'adjektive/jung.webp',
      leftText: 'Jung',
      leftTranslation: 'صغير السن',
      rightImage: 'adjektive/alt.webp',
      rightText: 'Alt',
      rightTranslation: 'كبير السن'
    },
    {
      leftImage: 'adjektive/kalt.webp',
      leftText: 'Kalt',
      leftTranslation: 'بارد',
      rightImage: 'adjektive/heiß.webp',
      rightText: 'Heiß',
      rightTranslation: 'حار'
    },
    {
      leftImage: 'adjektive/langsam.webp',
      leftText: 'Langsam',
      leftTranslation: 'بطيء',
      rightImage: 'adjektive/schnell.webp',
      rightText: 'Schnell',
      rightTranslation: 'سريع'
    },
    {
      leftImage: 'adjektive/schön.webp',
      leftText: 'Schön',
      leftTranslation: 'جميل',
      rightImage: 'adjektive/hässlich.webp',
      rightText: 'Hässlich',
      rightTranslation: 'قبيح'
    },
    {
      leftImage: 'adjektive/schwach.webp',
      leftText: 'Schwach',
      leftTranslation: 'ضعيف',
      rightImage: 'adjektive/stark.webp',
      rightText: 'Stark',
      rightTranslation: 'قوي'
    },
    {
      leftImage: 'adjektive/schwer.webp',
      leftText: 'Schwer',
      leftTranslation: 'ثقيل',
      rightImage: 'adjektive/leicht.webp',
      rightText: 'Leicht',
      rightTranslation: 'خفيف'
    },
  ];

  currentIndex = 0;
  leftImage = this.adjectives[this.currentIndex].leftImage;
  leftText = this.adjectives[this.currentIndex].leftText;
  leftTranslation = this.adjectives[this.currentIndex].leftTranslation;
  rightImage = this.adjectives[this.currentIndex].rightImage;
  rightText = this.adjectives[this.currentIndex].rightText;
  rightTranslation = this.adjectives[this.currentIndex].rightTranslation;
  textColor = 'white';
  isSlideComplete = false;
  intervalId: any; // Declare intervalId here

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startAnimation();
    }
  }

  startAnimation(): void {
    console.log('start')
    this.animateSlides();

    // Start interval for changing adjectives
    this.intervalId = setInterval(() => {
      this.nextAdjective();
    }, 5000); // Change every 5 seconds
  }

  stopAnimation(): void {
    if (this.intervalId) {
      console.log('stop')
      clearInterval(this.intervalId); // Stop the interval
      this.intervalId = null; // Reset the intervalId
    }
  }

  animateSlides(): void {
    const imageSlideLeft = document.querySelector('.image-slide-left') as HTMLElement;
    const imageSlideRight = document.querySelector('.image-slide-right') as HTMLElement;

    if (imageSlideLeft && imageSlideRight) {
      imageSlideLeft.addEventListener('animationend', () => {
        this.isSlideComplete = true;
        // Animation complete, now waiting to switch adjectives
      });

      imageSlideRight.addEventListener('animationend', () => {
        this.isSlideComplete = true;
      });
    }
  }

  nextAdjective(): void {
    this.currentIndex = (this.currentIndex + 1) % this.adjectives.length;
    const adjective = this.adjectives[this.currentIndex];

    this.leftImage = adjective.leftImage;
    this.leftText = adjective.leftText;
    this.speak(this.leftText);
    this.leftTranslation = adjective.leftTranslation;
    this.rightImage = adjective.rightImage;
    this.rightText = adjective.rightText;
    this.speak(this.rightText);
    this.rightTranslation = adjective.rightTranslation;

    this.isSlideComplete = false; // Hide text container until animation is complete

    // Restart animations
    const imageSlideLeft = document.querySelector('.image-slide-left') as HTMLElement;
    const imageSlideRight = document.querySelector('.image-slide-right') as HTMLElement;

    if (imageSlideLeft && imageSlideRight) {
      this.renderer.removeClass(imageSlideLeft, 'slide-complete');
      this.renderer.removeClass(imageSlideRight, 'slide-complete');
      
      // Trigger reflow
      void imageSlideLeft.offsetWidth;
      void imageSlideRight.offsetWidth;

      this.renderer.addClass(imageSlideLeft, 'slide-complete');
      this.renderer.addClass(imageSlideRight, 'slide-complete');
    }

    // Restart the animation
    this.animateSlides();
  }

  speak(text: string): void {
    if (isPlatformBrowser(this.platformId) && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'de-DE'; // Sprache auf Deutsch setzen
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn('Speech synthesis not supported in this browser.');
    }
  }

  ngOnDestroy(): void {
    this.stopAnimation();
  }
}