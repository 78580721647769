<div class="container">
<div class="box mainbox">
    <h2><a href="https://lernspiel.modakira.com/spiel.html?category=1" class="nav-link">اسماء (Nomen)</a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/zahlen" class="nav-link"> ارقام (Zahlen) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/kalendar" class="nav-link"> أيام وأشهر (Tage und Monate) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/verben" class="nav-link"> أفعال (Verben) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/farben" class="nav-link"> ألوان (Farben)</a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/adjektive" class="nav-link"> الصفات (Adjektive)</a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/texte" class="nav-link"> نصوص (Texte) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/geschichten" class="nav-link"> قصص (Geschichten) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/allgemeinwissen" class="nav-link"> معرفة عامة (Allgemeinwissen) </a></h2>
</div>
<div class="box mainbox">
    <h2><a href="/deklination" class="nav-link">  الانحدار (Deklination) </a></h2>
</div>
</div>
