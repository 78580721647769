import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-farben',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './farben.component.html',
  styleUrls: ['./farben.component.scss']
})
export class FarbenComponent implements AfterViewInit {
  colors: string[] = ['Blau', 'Braun', 'Gelb', 'Grün', 'Rot', 'Schwarz', 'Weiss'];
  currentColorIndex: number = 0;
  text: string = this.colors[this.currentColorIndex];
  color: string = this.text;
  example: string = ''; // Beispielsatz
  arabicTranslation: string = ''; // Arabische Übersetzung
  isSlideComplete: boolean = false;

  // Typdefinitionen für die Beispiele und Übersetzungen
  examples: { [key: string]: string } = {
    Blau: 'Der Himmel ist blau',
  Braun: 'Die Erde ist braun',
  Gelb: 'Die Sonne ist gelb',
  Grün: 'Die Blätter sind grün',
  Rot: 'Die Rose ist rot',
  Schwarz: 'Die Nacht ist schwarz',
  Weiss: 'Die Wolke ist weiss'
}

  arabicTranslations: { [key: string]: string } = {
    'Blau': 'السماء زرقاء',
    'Braun': 'التربة بنية',
    'Gelb': 'الشمس صفراء',
    'Grün': 'الأوراق خضراء',
    'Rot': 'الوردة حمراء',
    'Schwarz': 'الليل أسود',
    'Weiss': 'السحابة بيضاء'
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const imageSlide = document.querySelector('.image-slide') as HTMLElement;
      if (imageSlide) {
        imageSlide.addEventListener('animationend', () => {
          this.isSlideComplete = true;
          this.speak(this.text);
          this.example = this.examples[this.text] || ''; // Sicherheitshalber Default-Wert hinzufügen
          this.speak(this.example);
          this.arabicTranslation = this.arabicTranslations[this.text] || ''; // Sicherheitshalber Default-Wert hinzufügen
        });
      }
    }
  }

  repeatLogic(): void {
    if (isPlatformBrowser(this.platformId)) {
      const imageSlide = document.querySelector('.image-slide') as HTMLElement;
      if (imageSlide) {
        imageSlide.addEventListener('animationend', () => {
          this.isSlideComplete = true;
          this.speak(this.examples[this.text]);
          this.example = this.examples[this.text] || ''; // Sicherheitshalber Default-Wert hinzufügen
          // this.speak(this.examples[this.text]);
          this.arabicTranslation = this.arabicTranslations[this.text] || ''; // Sicherheitshalber Default-Wert hinzufügen
        });
      }
    }
  }

  speak(text: string): void {
    if (isPlatformBrowser(this.platformId) && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'de-DE'; // Sprache auf Deutsch setzen
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn('Speech synthesis not supported in this browser.');
    }
  }

  speakText(): void {
    this.speak(this.text);
  }

  nextColor(): void {
    // Update the color and example before restarting the animation
    this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;
    this.text = this.colors[this.currentColorIndex];
    this.color = this.text;
    this.example = this.examples[this.text] || ''; // Default value
    this.arabicTranslation = this.arabicTranslations[this.text] || ''; // Default value
    
    // Ensure the text container is hidden initially
    this.isSlideComplete = false;
  
    // Restart the slide animation
    const imageSlide = document.querySelector('.image-slide') as HTMLElement;
    if (imageSlide) {
      // Reset slideComplete class to restart animation
      imageSlide.classList.remove('slide-complete');
      
      // Force reflow to ensure animation restarts
      void imageSlide.offsetWidth; // Trigger reflow
      
      // Reapply the class to restart the animation
      imageSlide.classList.add('slide-complete');
    }
  
    // Speak the new text
    this.speak(this.text);
    this.speak(this.example);
  
    // After a delay, set isSlideComplete to true to show the text
    setTimeout(() => {
      this.isSlideComplete = true;
    }, 1000); // Match the duration of your slide animation
  }
  
  
}
