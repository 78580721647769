import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID, Renderer2, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-verben',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './verben.component.html',
  styleUrls: ['./verben.component.scss'] // styleUrl -> styleUrls
})
export class VerbenComponent implements AfterViewInit {

  private currentRow: number = 1;
  private rows!: NodeListOf<HTMLTableRowElement>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.rows = this.el.nativeElement.querySelectorAll('#conjugationTable tbody tr');
      
      const startButton = this.el.nativeElement.querySelector('#startButton') as HTMLButtonElement;

      if (startButton) {
        this.renderer.listen(startButton, 'click', () => {
          window.speechSynthesis.onvoiceschanged = () => {
            setTimeout(() => this.highlightNextRow(), 1000); // Startverzögerung von 1 Sekunde
          };
        });
      } else {
        console.error('Start button not found');
      }
    }
  }

  private speak(text: string): void {
    if (isPlatformBrowser(this.platformId) && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'de-DE'; // Sprache auf Deutsch setzen
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn('Speech synthesis not supported in this browser.');
    }
  }

  private highlightNextRow(): void {
    if (this.currentRow < this.rows.length) {
      this.rows.forEach(row => this.renderer.removeClass(row, 'highlight'));
      this.renderer.addClass(this.rows[this.currentRow], 'highlight');
      const germanText = this.rows[this.currentRow].cells[0].innerText;
      this.speak(germanText);
      this.currentRow++;
      setTimeout(() => this.highlightNextRow(), 2000); // Pause für 2 Sekunden
    }
  }
}
