import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-kalendar',
  templateUrl: './kalendar.component.html',
  styleUrls: ['./kalendar.component.scss']
})
export class KalendarComponent implements OnInit {
  days = [
    { de: 'Montag, 01. Januar 2024', ar: 'الإثنين، 01 يناير 2024', spoken: 'Montag, den ersten Januar zweitausendvierundzwanzig' },
    { de: 'Dienstag, 02. Januar 2024', ar: 'الثلاثاء، 02 يناير 2024', spoken: 'Dienstag, den zweiten Januar zweitausendvierundzwanzig' },
    { de: 'Mittwoch, 03. Januar 2024', ar: 'الأربعاء، 03 يناير 2024', spoken: 'Mittwoch, den dritten Januar zweitausendvierundzwanzig' },
    { de: 'Donnerstag, 04. Januar 2024', ar: 'الخميس، 04 يناير 2024', spoken: 'Donnerstag, den vierten Januar zweitausendvierundzwanzig' },
    { de: 'Freitag, 05. Januar 2024', ar: 'الجمعة، 05 يناير 2024', spoken: 'Freitag, den fünften Januar zweitausendvierundzwanzig' },
    { de: 'Samstag, 06. Januar 2024', ar: 'السبت، 06 يناير 2024', spoken: 'Samstag, den sechsten Januar zweitausendvierundzwanzig' },
    { de: 'Sonntag, 07. Januar 2024', ar: 'الأحد، 07 يناير 2024', spoken: 'Sonntag, den siebten Januar zweitausendvierundzwanzig' },
    { de: 'Montag, 08. Januar 2024', ar: 'الإثنين، 08 يناير 2024', spoken: 'Montag, den achten Januar zweitausendvierundzwanzig' },
    { de: 'Dienstag, 09. Januar 2024', ar: 'الثلاثاء، 09 يناير 2024', spoken: 'Dienstag, den neunten Januar zweitausendvierundzwanzig' },
    { de: 'Mittwoch, 10. Januar 2024', ar: 'الأربعاء، 10 يناير 2024', spoken: 'Mittwoch, den zehnten Januar zweitausendvierundzwanzig' },
    { de: 'Donnerstag, 11. Januar 2024', ar: 'الخميس، 11 يناير 2024', spoken: 'Donnerstag, den elften Januar zweitausendvierundzwanzig' },
    { de: 'Freitag, 12. Januar 2024', ar: 'الجمعة، 12 يناير 2024', spoken: 'Freitag, den zwölften Januar zweitausendvierundzwanzig' },
    { de: 'Samstag, 13. Januar 2024', ar: 'السبت، 13 يناير 2024', spoken: 'Samstag, den dreizehnten Januar zweitausendvierundzwanzig' },
    { de: 'Sonntag, 14. Januar 2024', ar: 'الأحد، 14 يناير 2024', spoken: 'Sonntag, den vierzehnten Januar zweitausendvierundzwanzig' },
    { de: 'Montag, 15. Januar 2024', ar: 'الإثنين، 15 يناير 2024', spoken: 'Montag, den fünfzehnten Januar zweitausendvierundzwanzig' }
  ];

  currentDayIndex = 0;

  dayDisplay: string = '';
  arabic: string = '';
  spoken: string = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    this.updateDisplay();
  }

  nextDay(): void {
    if (this.currentDayIndex < this.days.length - 1) {
      this.currentDayIndex++;
      this.updateDisplay();
    }
  }

  updateDisplay(): void {
    const currentDay = this.days[this.currentDayIndex];
    this.dayDisplay = currentDay.de;
    this.arabic = currentDay.ar;
    this.spoken = currentDay.spoken;
    this.speak(currentDay.spoken);
  }

  speak(text: string): void {
    if (isPlatformBrowser(this.platformId) && 'speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'de-DE'; // Sprache auf Deutsch setzen
      window.speechSynthesis.speak(utterance);
    } else {
      console.warn('Speech synthesis not supported in this browser.');
    }
  }
}
