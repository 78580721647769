import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID, OnInit } from '@angular/core';

@Component({
  selector: 'app-zahlen',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './zahlen.component.html',
  styleUrls: ['./zahlen.component.scss']
})
export class ZahlenComponent implements OnInit {
  hits = 0;
  hitsText: string = '';
  fontSize: string = '48px';
  circleFontSize: string = '48px';
  intervalId: any = null; // To keep track of the interval

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    this.updateDisplay();
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    // You can remove this if you no longer want to use key events
    if ([49, 50, 51, 52, 53, 54, 55, 56, 57, 48].includes(event.keyCode)) { // Key codes for 1-9 and 0
      this.addHit();
    }
  }

  startCounting(): void {
    // Ensure only one interval is running
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      this.addHit();
      if (this.hits >= 1000000000000) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }, 2000); // Adjust the interval as needed
  }

  stopCounting(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  addHit(): void {
    const sequence = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 40, 50, 60, 70, 80, 90, 100, 101, 102, 1000, 10000, 100000, 1000000, 1000000000, 1000000000000];
    const currentIndex = sequence.indexOf(this.hits);
    this.hits = sequence[currentIndex + 1] || this.hits; // Fallback to the current hits if out of range
    this.updateDisplay();
  }

  updateDisplay(): void {
    this.hitsText = this.mapNumber(this.hits.toString());
    this.speak(this.hits.toString());

    if (this.hits >= 21 && this.hits < 1000) {
      this.fontSize = '32px';
    } else if (this.hits >= 1000) {
      this.fontSize = '24px';
    } else {
      this.fontSize = '48px';
    }

    if (this.hits >= 1000) {
      this.circleFontSize = '32px';
    } else if (this.hits >= 1000000000000) {
      this.circleFontSize = '18px';
    } else {
      this.circleFontSize = '48px';
    }
  }

  speak(text: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'de-DE'; // Sprache auf Deutsch setzen
        window.speechSynthesis.speak(utterance);
      } else {
        console.warn('Speech synthesis not supported in this browser.');
      }
    }
  }

  mapNumber(number: string): string {
    const numberMap: { [key: string]: string } = {
      "0": "Null",
      "1": "Eins",
      "2": "Zwei",
      "3": "Drei",
      "4": "Vier",
      "5": "Fünf",
      "6": "Sechs",
      "7": "Sieben",
      "8": "Acht",
      "9": "Neun",
      "10": "Zehn",
      "11": "Elf",
      "12": "Zwölf",
      "13": "Dreizehn",
      "14": "Vierzehn",
      "15": "Fünfzehn",
      "16": "Sechzehn",
      "17": "Siebzehn",
      "18": "Achtzehn",
      "19": "Neunzehn",
      "20": "Zwanzig",
      "21": "Einundzwanzig",
      "22": "Zweiundzwanzig",
      "23": "Dreiundzwanzig",
      "24": "Vierundzwanzig",
      "25": "Fünfundzwanzig",
      "26": "Sechsundzwanzig",
      "27": "Siebenundzwanzig",
      "28": "Achtundzwanzig",
      "29": "Neunundzwanzig",
      "30": "Dreißig",
      "40": "Vierzig",
      "50": "Fünfzig",
      "60": "Sechzig",
      "70": "Siebzig",
      "80": "Achtzig",
      "90": "Neunzig",
      "100": "Hundert",
      "101": "Hunderteins",
      "102": "Hundertzwei",
      "1000": "Tausend",
      "10000": "Zehntausend",
      "100000": "Hunderttausend",
      "1000000": "Eine Million",
      "1000000000": "Eine Milliarde",
      "1000000000000": "Eine Billion"
    };

    return numberMap[number] || "Number out of range";
  }
}
