<div class="container">
    <div class="box mainbox" [ngStyle]="{'font-size': fontSize}">
        <div class="circle counter-circle" [ngStyle]="{'font-size': circleFontSize}">
            {{ hits }}
        </div>
        <div class="text-highlight">{{ hitsText }}</div>
    </div>
    <div style="margin-top:50px">
        <button class="stop-button" (click)="stopCounting()">Stop</button>
        <button class="start-button" (click)="startCounting()">Start</button>
    </div>
</div>