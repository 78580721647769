import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-texte',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './texte.component.html',
  styleUrl: './texte.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TexteComponent {
  selectedTab = 1;
  translateX = 'translateX(0)';
  currentIndex = 0;
  maxTabs = 2; // Anzahl der sichtbaren Tabs

  // selectTab(tabIndex: number): void {
  //   this.selectedTab = tabIndex;
  // }

  // nextTab(): void {
  //   const tabs = document.querySelectorAll('.tabs button');
  //   const totalTabs = tabs.length;
  //   if (this.currentIndex < totalTabs - this.maxTabs) {
  //     this.currentIndex++;
  //     this.updateTranslate();
  //   }
  // }

  // prevTab(): void {
  //   if (this.currentIndex > 0) {
  //     this.currentIndex--;
  //     this.updateTranslate();
  //   }
  // }

  selectTab(event: Event) {
    const selectElement = event.target as HTMLSelectElement; // Type assertion to HTMLSelectElement
    this.selectedTab = +selectElement.value; // Convert string to number
    //this.updateTabContent();
  }
  

  updateTranslate(): void {
    this.translateX = `translateX(-${this.currentIndex * 100}px)`; // Passe den Wert an die Breite der Tabs an
  }
}
