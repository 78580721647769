import { Routes } from '@angular/router';
import { AboutComponent } from './routes/about/about.component';
import { HomeComponent } from './routes/home/home.component';
import { LerneComponent } from './components/lerne/lerne.component';
import { ZahlenComponent } from './components/zahlen/zahlen.component';
import { KalendarComponent } from './components/kalendar/kalendar.component';
import { FarbenComponent } from './components/farben/farben.component';
import { VerbenComponent } from './components/verben-old/verben.component';
import { AdjektiveComponent } from './components/adjektive/adjektive.component';
import { TexteComponent } from './components/texte/texte.component';
import { GeschichtenComponent } from './components/geschichten/geschichten.component';
import { AllgemeinwissenComponent } from './components/allgemeinwissen/allgemeinwissen.component';
import { DeklinationComponent } from './components/deklination/deklination.component';

export const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'lerne', component: LerneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'zahlen', component: ZahlenComponent },
    { path: 'kalendar', component: KalendarComponent },
    { path: 'farben', component: FarbenComponent },
    { path: 'verben', component: VerbenComponent },
    { path: 'adjektive', component: AdjektiveComponent },
    { path: 'texte', component: TexteComponent },
    { path: 'geschichten', component: GeschichtenComponent },
    { path: 'allgemeinwissen', component: AllgemeinwissenComponent},
    { path: 'deklination', component: DeklinationComponent}
];
