<div dir="ltr" style="padding-top: 15px; padding: 8px;">
    <h1>Rotkäppchen</h1>
    <div class="story">
        <p>Es war einmal ein kleines Mädchen. Sie heißt Rotkäppchen. Sie trägt immer eine rote Mütze. Ihre Oma ist
            krank. Rotkäppchen bringt der Oma Essen.</p>

        <p>Rotkäppchen geht durch den Wald. Sie trifft einen Wolf. Der Wolf fragt: „Wohin gehst du?“ Rotkäppchen sagt:
            „Ich gehe zur Oma.“</p>

        <p>Der Wolf denkt nach. Er will nicht Rotkäppchen im Wald fressen. Er hat einen besseren Plan. Er will zuerst
            zur Oma gehen und sie fressen. Dann kann er auf Rotkäppchen warten und auch sie fressen. Der Wolf geht
            schnell zum Haus von der Oma. Er geht ins Haus und versteckt sich in ihrem Bett.</p>

        <p>Rotkäppchen kommt an und sieht den Wolf. Sie sagt: „Oma, warum hast du so große Ohren?“ Der Wolf sagt: „Mit
            großen Ohren, kann ich dich besser hören.“</p>

        <p>Rotkäppchen fragt weiter: „Oma, warum hast du so große Augen?“ Der Wolf sagt: „Mit großen Augen, kann ich
            dich besser sehen.“</p>

        <p>„Oma, warum hast du so eine große Nase?“ fragt Rotkäppchen. Der Wolf sagt: „Mit einer großen Nase, kann ich
            dich besser riechen.“</p>

        <p>„Oma, warum hast du so große Zähne?“ fragt Rotkäppchen. Der Wolf springt auf und sagt: „Ich will dich
            fressen!“</p>

        <p>Ein Jäger hört Rotkäppchen schreien. Er kommt ins Haus und hilft. Der Jäger rettet Rotkäppchen und die Oma.
            Sie sind alle sicher.</p>

        <p>Rotkäppchen lernt: Sei vorsichtig im Wald.</p>
    </div>
</div>