import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, NgZone, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeComponent implements OnInit {
  introHeader: string = `مرحبا بكم.<br>
على هذا الموقع نضمن لكم تعلم الألمانية بشكل فعال.<br>
مواضيع مهمة للامتحان أو فيما يفيدك أيضا في حياتك اليومية ادا كنت تنوي العيش بألمانيا.<br>
تشارك معنا المباشرات على التيكتوك، الفيديوهات على اليوتيوب أو النصوص على الموقع.<br>
كل التوفيق!`;


  introParagraph: string = "اكتشفوا عالماً من المرح والمعرفة مع موقعنا الذي يقدم لكم محتوى متميزاً يجمع بين الفائدة والمتعة. سواء كنتم تبحثون عن مقالات تعليمية، ألعاب تفاعلية، فيديوهات تعليمية مشوقة، أو تجارب ممتعة تُغني معرفتكم، فإن موقعنا هو الوجهة المثالية لكم.";
  displayedHeader: string = '';
  displayedParagraph: string = '';
  showContinue: boolean = false;
  showBoxes: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone, private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('تعلم اللغة الألمانية: كلمات وجمل بسيطة للمبتدئين');
    this.meta.addTags([
      { name: 'description', content: 'تعلم اللغة الألمانية بكلمات بسيطة وجمل مهمة. مثالي للمبتدئين الذين يرغبون في اكتساب مهارات أساسية في اللغة الألمانية. استفد من دوراتنا المفيدة وأمثلتنا لتحضير نفسك للحياة في ألمانيا وفهم القواعد الأساسية للغة.' },
      { name: 'keywords', content: 'تعلم الألمانية، ألمانية A1، كلمات ألمانية بسيطة، جمل ألمانية مهمة، ألمانية للمبتدئين، قواعد اللغة الألمانية، ألمانية-عربية، الهجرة إلى ألمانيا، تعلم الألمانية مع أمثلة، دورة لغة ألمانية' }
    ]);
    this.typeIntroText();
  }

  typeIntroText() {
    this.ngZone.runOutsideAngular(() => {
      const fullHeader = this.introHeader;
      const fullParagraph = this.introParagraph;
      let currentIndex = 0;
      const typingSpeed = 25; // Speed of typing in milliseconds

      const typeText = () => {
        if (currentIndex < fullHeader.length) {
          this.displayedHeader += fullHeader.charAt(currentIndex);
        } else if (currentIndex < fullHeader.length + fullParagraph.length) {
          const paragraphIndex = currentIndex - fullHeader.length;
          this.displayedParagraph += fullParagraph.charAt(paragraphIndex);
        } else {
          this.ngZone.run(() => {
            this.showContinue = true;
          });
          return;
        }
        currentIndex++;
        setTimeout(() => {
          this.ngZone.run(() => {
            typeText();
          });
        }, typingSpeed);
      };

      typeText();
    });
  }
}