<header class="header">
    <a href="" class="logo"><img src="logo.png" alt="Modakira" width="70px"/></a>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    <ul class="menu">
        <!-- <li><a href="#work">Our Work</a></li> -->
        <li><a href="/lerne">العودة إلى الاختيار</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/contact">Contact</a></li>
    </ul>
</header>