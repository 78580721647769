<div class="container" style="margin-top: 100px;">
    <div class="image-slide" [ngClass]="{'slide-complete': isSlideComplete}">
      <img [src]="'farben/' + text + '.webp'" width="500" height="500" alt="Color Image">
    </div>
    
    <button class="btn1" (click)="speakText()">Spreche den Text aus</button>
    <button class="btn2" (click)="nextColor()">Nächste Farbe</button>
    
    <div class="text-container" *ngIf="isSlideComplete" width="100%">
      <span [ngStyle]="{'color': color}">{{ text }}</span>
      <div class="example">{{ example }}</div>
      <div class="arabic-translation">{{ arabicTranslation }}</div>
    </div>
  </div>
  