<div class="container">
    <table id="conjugationTable">
      <thead>
        <tr>
          <th>Deutsch</th>
          <th>Arabisch</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ich beginne</td>
          <td>أنا أبدأ</td>
        </tr>
        <tr>
          <td>Du beginnst</td>
          <td>أنت تبدأ</td>
        </tr>
        <tr>
          <td>Er/Sie/Es beginnt</td>
          <td>هو/هي/هو يبدأ</td>
        </tr>
        <tr>
          <td>Sie beginnen</td>
          <td>حضرتك تبدأ</td>
        </tr>
        <tr>
          <td>Wir beginnen</td>
          <td>نحن نبدأ</td>
        </tr>
        <tr>
          <td>Ihr beginnt</td>
          <td>أنتم تبدأون</td>
        </tr>
        <tr>
          <td>Sie beginnen</td>
          <td>هم يبدأون</td>
        </tr>
      </tbody>
    </table>
  
    <button id="startButton">Start</button>
  </div>
  
  <!-- <div class="watermark-container">
    <img src="../logo.png" alt="Logo" class="watermark-logo">
    <p class="watermark-text">Modakira.com</p>
  </div> -->
  