<div style="text-align: center;" class="contentfirst">
  <div class="intro" *ngIf="!showBoxes" style="text-align: center; padding-top: 50px; padding-bottom: 50px;">
    <!-- <h1>{{ displayedHeader }}</h1> -->
     <div style="width: 80%; margin: 0 auto;">
    <h1 [innerHTML]="displayedHeader" style="text-align: right; font-size: 24px;"></h1>
  </div>
    <!-- <p> {{ displayedParagraph  }}
    </p> -->
    <button *ngIf="showContinue"><a href="/lerne">دخول </a></button>
  </div>

  <div class="video-container">
    <iframe 
        src="https://www.youtube.com/embed/vhM7hsG4mFI" 
        title="YouTube video" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
</div>

  
  <div class="subscribe-kurs" style="padding-bottom: 20px;">
    دروس دعم و تقوية في اللغة الألمانية 🇩🇪 خصوصية، مباشرعلى التيكتوك، المرجو تفعيل خاصية النجمة 💫 أو الضغط على الرابط
    <br>
    <a href="https://vm.tiktok.com/ZGdRVSdUp">https://vm.tiktok.com/ZGdRVSdU</a>
  </div>
</div>
<script type="application/ld+json">
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": "Modakira",
      "description": "موداكيرا هو مكان للتعلم الممتع، يركز بشكل أساسي على اللغة الألمانية. هنا، يمكنك أن تشق طريقك نحو التمكن من اللغة، بغض النظر عن الدوافع الشخصية أو التطلعات للانتقال إلى ألمانيا. التعلم هنا مفتوح للجميع، الصغار والكبار على حد سواء",
      "url": "https://www.modakira.com"
    }
    </script>