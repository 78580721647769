import { CommonModule } from '@angular/common';
import { Component, OnInit, HostListener, ElementRef, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-deklination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deklination.component.html',
  styleUrl: './deklination.component.scss'
})
export class DeklinationComponent implements OnInit {
  currentIndex: number = 0;
  isPaused: boolean = false;
  declensionData = [
    {
        table: "Personalpronomen",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "ich", nominativ: "ich", akkusativ: "mich", dativ: "mir" },
            { name: "du", nominativ: "du", akkusativ: "dich", dativ: "dir" },
            { name: "er", nominativ: "er", akkusativ: "ihn", dativ: "ihm" },
            { name: "sie (f)", nominativ: "sie", akkusativ: "sie", dativ: "ihr" },
            { name: "es", nominativ: "es", akkusativ: "es", dativ: "ihm" },
            { name: "wir", nominativ: "wir", akkusativ: "uns", dativ: "uns" },
            { name: "ihr", nominativ: "ihr", akkusativ: "euch", dativ: "euch" },
            { name: "sie (pl)", nominativ: "sie", akkusativ: "sie", dativ: "ihnen" },
            { name: "Sie (formal)", nominativ: "Sie", akkusativ: "Sie", dativ: "Ihnen" },
        ],
        examples: [
            { nominativ: "Ich sehe.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">mich</span> im Spiegel.", dativ: "Er gibt <span style=\"color: #b700ff;\">mir</span> das Buch." },
            { nominativ: "Du siehst.", akkusativ: "Er sieht <span style=\"color: #b700ff;\">dich</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">dir</span> das Buch." },
            { nominativ: "Er kommt.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">ihn</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihm</span> den Schlüssel." },
            { nominativ: "Sie geht.", akkusativ: "Er sieht <span style=\"color: #b700ff;\">sie</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihr</span> die Tasche." },
            { nominativ: "Es steht da.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">es</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihm</span> das Spielzeug." },
            { nominativ: "Wir laufen.", akkusativ: "Sie sieht <span style=\"color: #b700ff;\">uns</span>.", dativ: "Er gibt <span style=\"color: #b700ff;\">uns</span> die Karten." },
            { nominativ: "Ihr kommt.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">euch</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">euch</span> das Buch." },
            { nominativ: "Sie sind da.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">sie</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihnen</span> die Briefe." },
            { nominativ: "Sie sind Lehrer.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">Sie</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">Ihnen</span> das Dokument." }
        ]
    },
    {
        table: "Possessivpronomen",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "mein", nominativ: "mein", akkusativ: "meinen", dativ: "meinem" },
            { name: "dein", nominativ: "dein", akkusativ: "deinen", dativ: "deinem" },
            { name: "sein", nominativ: "sein", akkusativ: "seinen", dativ: "seinem" },
            { name: "ihr (f)", nominativ: "ihr", akkusativ: "ihren", dativ: "ihrem" },
            { name: "unser", nominativ: "unser", akkusativ: "unseren", dativ: "unserem" },
            { name: "euer", nominativ: "euer", akkusativ: "euren", dativ: "eurem" },
            { name: "ihr (pl)", nominativ: "ihr", akkusativ: "ihren", dativ: "ihrem" },
            { name: "Ihr (formal)", nominativ: "Ihr", akkusativ: "Ihren", dativ: "Ihrem" },
        ],
        examples: [
            { nominativ: "Das ist <span style=\"color: #b700ff;\">mein</span> Buch.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">meinen</span> Hund.", dativ: "Ich gebe <span style=\"color: #b700ff;\">meinem</span> Freund das Buch." },
            { nominativ: "Das ist <span style=\"color: #b700ff;\">dein</span> Auto.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">deinen</span> Hund.", dativ: "Ich gebe <span style=\"color: #b700ff;\">deinem</span> Freund den Schlüssel." },
            { nominativ: "Das ist <span style=\"color: #b700ff;\">sein</span> Haus.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">seinen</span> Wagen.", dativ: "Ich gebe <span style=\"color: #b700ff;\">seinem</span> Bruder das Geschenk." },
            { nominativ: "Das ist <span style=\"color: #b700ff;\">ihr</span> Fahrrad.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">ihren</span> Stift.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihrem</span> Sohn das Geld." },
            { nominativ: "<span style=\"color: #b700ff;\">Unser</span> Garten ist groß.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">unseren</span> Baum.", dativ: "Ich gebe <span style=\"color: #b700ff;\">unserem</span> Nachbarn das Werkzeug." },
            { nominativ: "<span style=\"color: #b700ff;\">Euer</span> Zimmer ist sauber.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">euren</span> Tisch.", dativ: "Ich gebe <span style=\"color: #b700ff;\">eurem</span> Lehrer das Buch." },
            { nominativ: "Das ist <span style=\"color: #b700ff;\">ihr</span> Haus.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">ihren</span> Hund.", dativ: "Ich gebe <span style=\"color: #b700ff;\">ihrem</span> Vater den Brief." },
            { nominativ: "Ist das <span style=\"color: #b700ff;\">Ihr</span> Stift?", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">Ihren</span> Wagen.", dativ: "Ich gebe <span style=\"color: #b700ff;\">Ihrem</span> Mitarbeiter den Bericht." }
        ]
    },
    {
        table: "Bestimmte Artikel",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "Maskulin", nominativ: "der", akkusativ: "den", dativ: "dem" },
            { name: "Feminin", nominativ: "die", akkusativ: "die", dativ: "der" },
            { name: "Neutrum", nominativ: "das", akkusativ: "das", dativ: "dem" },
            { name: "Plural", nominativ: "die", akkusativ: "die", dativ: "den" },
        ],
        examples: [
            { nominativ: "<span style=\"color: #b700ff;\">Der</span> Mann liest.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">den</span> Mann.", dativ: "Ich gebe <span style=\"color: #b700ff;\">dem</span> Mann das Buch." },
            { nominativ: "<span style=\"color: #b700ff;\">Die</span> Frau kocht.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">die</span> Frau.", dativ: "Ich gebe <span style=\"color: #b700ff;\">der</span> Frau den Schlüssel." },
            { nominativ: "<span style=\"color: #b700ff;\">Das</span> Kind spielt.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">das</span> Kind.", dativ: "Ich gebe <span style=\"color: #b700ff;\">dem</span> Kind das Spielzeug." },
            { nominativ: "<span style=\"color: #b700ff;\">Die</span> Kinder lachen.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">die</span> Kinder.", dativ: "Ich gebe <span style=\"color: #b700ff;\">den</span> Kindern die Bälle." }
        ]
    },
    {
        table: "Unbestimmte Artikel",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "Maskulin", nominativ: "ein", akkusativ: "einen", dativ: "einem" },
            { name: "Feminin", nominativ: "eine", akkusativ: "eine", dativ: "einer" },
            { name: "Neutrum", nominativ: "ein", akkusativ: "ein", dativ: "einem" },
            { name: "Plural", nominativ: "-", akkusativ: "-", dativ: "-" },
        ],
        examples: [
            { nominativ: "<span style=\"color: #b700ff;\">Ein</span> Mann kommt.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">einen</span> Mann.", dativ: "Ich gebe <span style=\"color: #b700ff;\">einem</span> Mann das Buch." },
            { nominativ: "<span style=\"color: #b700ff;\">Eine</span> Frau kocht.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">eine</span> Frau.", dativ: "Ich gebe <span style=\"color: #b700ff;\">einer</span> Frau den Schlüssel." },
            { nominativ: "<span style=\"color: #b700ff;\">Ein</span> Kind spielt.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">ein</span> Kind.", dativ: "Ich gebe <span style=\"color: #b700ff;\">einem</span> Kind das Spielzeug." },
            { nominativ: "Kinder lachen.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">Kinder</span>.", dativ: "Ich gebe <span style=\"color: #b700ff;\">Kindern</span> die Bälle." }
        ]
    },
    {
        table: "Adjektivendungen mit bestimmten Artikeln",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "Maskulin", nominativ: "große", akkusativ: "großen", dativ: "großen" },
            { name: "Feminin", nominativ: "große", akkusativ: "große", dativ: "großen" },
            { name: "Neutrum", nominativ: "große", akkusativ: "große", dativ: "großen" },
            { name: "Plural", nominativ: "großen", akkusativ: "großen", dativ: "großen" },
        ],
        examples: [
            { nominativ: "Der <span style=\"color: #b700ff;\">große</span> Baum steht im Garten.", akkusativ: "Ich sehe den <span style=\"color: #b700ff;\">großen</span> Baum.", dativ: "Ich gebe dem <span style=\"color: #b700ff;\">großen</span> Baum Wasser." },
            { nominativ: "Die <span style=\"color: #b700ff;\">große</span> Blume ist schön.", akkusativ: "Ich sehe die <span style=\"color: #b700ff;\">große</span> Blume.", dativ: "Ich gebe der <span style=\"color: #b700ff;\">großen</span> Blume Wasser." },
            { nominativ: "Das <span style=\"color: #b700ff;\">große</span> Haus ist alt.", akkusativ: "Ich sehe das <span style=\"color: #b700ff;\">große</span> Haus.", dativ: "Ich gebe dem <span style=\"color: #b700ff;\">großen</span> Haus neue Fenster." },
            { nominativ: "Die <span style=\"color: #b700ff;\">großen</span> Hunde bellen.", akkusativ: "Ich sehe die <span style=\"color: #b700ff;\">großen</span> Hunde.", dativ: "Ich gebe den <span style=\"color: #b700ff;\">großen</span> Hunden Futter." }
        ]
    },
    {
        table: "Adjektivendungen mit unbestimmten Artikeln",
        cases: ["Nominativ", "Akkusativ", "Dativ"],
        categories: [
            { name: "Maskulin", nominativ: "großer", akkusativ: "großen", dativ: "großen" },
            { name: "Feminin", nominativ: "große", akkusativ: "große", dativ: "großen" },
            { name: "Neutrum", nominativ: "großes", akkusativ: "großes", dativ: "großen" },
            { name: "Plural", nominativ: "große", akkusativ: "große", dativ: "großen" },
        ],
        examples: [
            { nominativ: "Ein <span style=\"color: #b700ff;\">großer</span> Baum steht im Garten.", akkusativ: "Ich sehe einen <span style=\"color: #b700ff;\">großen</span> Baum.", dativ: "Ich gebe einem <span style=\"color: #b700ff;\">großen</span> Baum Wasser." },
            { nominativ: "Eine <span style=\"color: #b700ff;\">große</span> Blume ist schön.", akkusativ: "Ich sehe eine <span style=\"color: #b700ff;\">große</span> Blume.", dativ: "Ich gebe einer <span style=\"color: #b700ff;\">großen</span> Blume Wasser." },
            { nominativ: "Ein <span style=\"color: #b700ff;\">großes</span> Haus ist alt.", akkusativ: "Ich sehe ein <span style=\"color: #b700ff;\">großes</span> Haus.", dativ: "Ich gebe einem <span style=\"color: #b700ff;\">großen</span> Haus neue Fenster." },
            { nominativ: "<span style=\"color: #b700ff;\">Große</span> Hunde bellen.", akkusativ: "Ich sehe <span style=\"color: #b700ff;\">große</span> Hunde.", dativ: "Ich gebe <span style=\"color: #b700ff;\">großen</span> Hunden Futter." }
        ]
    },
];
constructor(
  private elRef: ElementRef,
  private renderer: Renderer2,
  @Inject(PLATFORM_ID) private platformId: Object
) {}

ngOnInit(): void {
  if (isPlatformBrowser(this.platformId)) {
    this.showNextTable();
  }
}

createTable(data: any, useExamples = false): void {
  if (isPlatformBrowser(this.platformId)) {
    const tableContainer = this.elRef.nativeElement.querySelector('#table-container');
    if (tableContainer) {
      this.renderer.setProperty(tableContainer, 'innerHTML', ''); // Clear previous content

      const title = this.renderer.createElement('h2');
      this.renderer.setProperty(title, 'innerHTML', useExamples ? `${data.table} Beispiele` : data.table);
      this.renderer.setStyle(title, 'textAlign', 'center');
      this.renderer.appendChild(tableContainer, title);

      const table = this.renderer.createElement('table');
      const thead = this.renderer.createElement('thead');
      const tbody = this.renderer.createElement('tbody');

      // Header Row
      const headerRow = this.renderer.createElement('tr');
      data.cases.forEach((c: string) => {
        const th = this.renderer.createElement('th');
        this.renderer.setProperty(th, 'innerHTML', c);
        this.renderer.appendChild(headerRow, th);
      });
      this.renderer.appendChild(thead, headerRow);

      // Body Rows
      const rowsData = useExamples ? data.examples : data.categories;
      rowsData.forEach((row: any) => {
        const tr = this.renderer.createElement('tr');

        if (row.nominativ !== undefined) {
          const nominativCell = this.renderer.createElement('td');
          this.renderer.setProperty(nominativCell, 'innerHTML', row.nominativ);
          this.renderer.appendChild(tr, nominativCell);
        }

        if (row.akkusativ !== undefined) {
          const akkusativCell = this.renderer.createElement('td');
          this.renderer.setProperty(akkusativCell, 'innerHTML', row.akkusativ);
          this.renderer.appendChild(tr, akkusativCell);
        }

        if (row.dativ !== undefined) {
          const dativCell = this.renderer.createElement('td');
          this.renderer.setProperty(dativCell, 'innerHTML', row.dativ);
          this.renderer.appendChild(tr, dativCell);
        }

        this.renderer.appendChild(tbody, tr);
      });

      this.renderer.appendChild(table, thead);
      this.renderer.appendChild(table, tbody);
      this.renderer.appendChild(tableContainer, table);
    }
  }
}

highlightCells(cells: HTMLTableCellElement[], callback: Function): void {
  if (isPlatformBrowser(this.platformId)) {
    let index = 0;

    const highlightNext = () => {
      if (this.isPaused) {
        setTimeout(highlightNext, 200);
        return;
      }

      if (index > 0) {
        this.renderer.removeClass(cells[index - 1], 'highlight');
      }
      if (index < cells.length) {
        this.renderer.addClass(cells[index], 'highlight');
        index++;
        setTimeout(highlightNext, 2000);
      } else {
        if (callback) {
          callback();
        }
      }
    };

    highlightNext();
  }
}

showExamples(): void {
  if (isPlatformBrowser(this.platformId)) {
    this.createTable(this.declensionData[this.currentIndex], true);
    const cells = Array.from(this.elRef.nativeElement.querySelectorAll('tbody td')) as HTMLTableCellElement[];
    if (cells.length > 0) {
      this.highlightCells(cells, () => {
        if (!this.isPaused) {
          this.currentIndex++;
          if (this.currentIndex < this.declensionData.length) {
            setTimeout(() => this.showNextTable(), 2000);
          }
        }
      });
    }
  }
}

showNextTable(): void {
  if (isPlatformBrowser(this.platformId)) {
    this.createTable(this.declensionData[this.currentIndex]);
    const cells = Array.from(this.elRef.nativeElement.querySelectorAll('tbody td')) as HTMLTableCellElement[];
    if (cells.length > 0) {
      this.highlightCells(cells, () => this.showExamples());
    }
  }
}

@HostListener('window:keydown', ['$event'])
handleKeydown(event: KeyboardEvent): void {
  if (isPlatformBrowser(this.platformId)) {
    switch (event.key) {
      case ' ': // Space to pause/resume
        this.isPaused = !this.isPaused;
        break;
      case 'ArrowRight': // Right arrow to go to the next table manually
        if (this.isPaused && this.currentIndex < this.declensionData.length - 1) {
          this.currentIndex++;
          this.showNextTable();
        }
        break;
      case 'ArrowLeft': // Left arrow to go to the previous table manually
        if (this.isPaused && this.currentIndex > 0) {
          this.currentIndex--;
          this.showNextTable();
        }
        break;
    }
  }
}
}