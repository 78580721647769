<div class="content" dir="ltr">
    <h1>Die Deutsche Einheit</h1>
    <p>Die Deutsche Einheit bedeutet, dass Deutschland wieder ein Land geworden ist. Früher ist Deutschland in zwei Teile geteilt: Westdeutschland (die Bundesrepublik Deutschland) und Ostdeutschland (die Deutsche Demokratische Republik). Das ist nach dem Zweiten Weltkrieg im Jahr 1949 passiert. Westdeutschland hat den westlichen Ländern gefolgt und war wirtschaftlich und militärisch vom Kapitalismus geprägt. Die Hauptstadt von Westdeutschland war Bonn. Ostdeutschland hat das sozialistische System gehabt und hat eng mit der Sowjetunion zusammengearbeitet. Die Hauptstadt von Ostdeutschland war Ost-Berlin.</p>

    <p>Am 9. November 1989 ist die Berliner Mauer gefallen. Die Mauer hat Berlin in zwei Teile geteilt. Sie war ein Symbol für die Teilung Deutschlands. Viele Menschen haben bessere Lebensbedingungen und mehr Freiheiten gewollt. Deshalb haben viele Menschen in Ostdeutschland demonstriert. Die Regierung hat nachgegeben, und die Menschen durften wieder in den Westen reisen.</p>

    <p>Am 3. Oktober 1990 hat sich Deutschland wieder vereinigt. Dieses Datum ist der „Tag der Deutschen Einheit“ und ein Feiertag in Deutschland. Deutschland war jetzt wieder ein Land und nicht mehr in Ost und West geteilt.</p>

    <p>Die Deutsche Einheit hat viele Veränderungen gebracht. Viele Menschen aus Ostdeutschland sind in den Westen gezogen, weil es dort mehr Arbeit und bessere Möglichkeiten gegeben hat. Heute ist Deutschland ein starkes Land in Europa, und die Menschen leben zusammen ohne die alten Trennungen.</p>

    <h2>Daten und Fakten:</h2>
    <ul>
        <li>1949: Teilung von Deutschland in Ost und West.</li>
        <li>1961: Bau der Berliner Mauer.</li>
        <li>Bonn war die Hauptstadt von Westdeutschland, und Ost-Berlin war die Hauptstadt von Ostdeutschland.</li>
        <li>9. November 1989: Fall der Berliner Mauer.</li>
        <li>3. Oktober 1990: Deutsche Wiedervereinigung.</li>
    </ul>

    <p>Die Deutsche Einheit hat viel Positives gebracht, aber es hat auch Herausforderungen gegeben. Bis heute arbeiten Ost- und Westdeutschland daran, die Unterschiede zwischen den Regionen zu verringern.</p>
</div>